<template>
	<el-container class="add_space">
		<el-header class="go_back">
			<el-button type="text" @click="goBack"> <i class="el-icon-arrow-left"></i>返回</el-button>
		</el-header>
		<el-main>
			<el-col class="title lh30">基础信息</el-col>
			<div class="pic">
				<el-col class="lh30 must_red">上传照片</el-col>
				<CardUpload :uploadCardAddress="uploadLogoAddress" :imgLimit=20 :cardplanList="imgList" @success="imgUploadSuccess"
				 @CardPlanRemove="imgRemove" @handleExceed="handleExceed" :size="4194320" width='100%' height='100%'>
				</CardUpload>
				<div class="tips">(最多上传20张图片)</div>
				<div class="tips">建议上传大小为1.5：1的图片</div>
				<div class="tips">仅支持jpg、jpeg、png格式大小不能大于4M</div>
			</div>
			<div>
				<el-form :model="form" ref="form" label-width="100px" class="padding" style="overflow:hidden;" label-position="top">
					<el-row :span="18" :gutter="24">
						<el-col :span="6">
							<el-form-item label="空间名称" prop="name" :rules="[{required: true, message: '空间名称不能为空', trigger: 'blur'},{min: 1, max:20,message: '长度不能大于20个字符', trigger: 'change'}]">
								<el-input class="width_360" v-model="form.name" placeholder="请输入空间名称"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col class="lh30">地理位置</el-col>
						<el-col :span="7">
							<el-form-item label="省" prop='province_id' :rules="[{required: true, message: '所属省份不能为空', trigger: 'change'}]">
								<el-select v-model="form.province_id" filterable placeholder="可搜索省份" @change="areaChange1">
									<el-option v-for="item in provinceData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="市" prop='city_id' :rules="[{required: true, message: '所属市级不能为空', trigger: 'change'}]">
								<el-select placeholder="请选择" v-model="form.city_id" filterable @change="areaChange2">
									<el-option v-for="item in cityData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="7" style="padding-left: 5px;">
							<el-form-item label="区" prop='district_id' :rules="[{required: true, message: '所属区县不能为空', trigger: 'change'}]">
								<el-select placeholder="请选择" filterable v-model="form.district_id">
									<el-option v-for="item in districtData" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="7">
							<el-form-item label="详细地址" prop="address" :rules="[{required: true, message: '详细地址不能为空', trigger: 'change'},{min: 1, max:40,message: '长度不能大于40个字符', trigger: 'change'}]">
								<el-input class="width_360" v-model="form.address" placeholder="请输入具体位置" style="width:80%"></el-input>
							</el-form-item>
						</el-col>
						<el-col  :span="5" >
							<el-form-item label="空间级别" prop="level_id" :rules="[{required: true, message: '空间级别不能为空', trigger: 'change'}]">
							<el-select placeholder="请选择"  v-model="form.level_id" >
									<el-option v-for="item in level_ids" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="6">
							<el-form-item label="类型">
								<el-select placeholder="请选择" multiple v-model="form.park_type" :multiple-limit="limit">
									<el-option v-for="item in parkData" :key="item.id" :label="item.title" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="18">
							<el-form-item label="单位面积" prop="measure" :rules="[{required: true, message: '单位面积不能为空', trigger: 'change'}]">
								<el-input class="width_140" v-model="form.measure" @change="measureChange" placeholder="请输入单位面积"></el-input>
								<span class="span_left4">m²</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="6">
							<el-form-item label="朝向">
								<el-select placeholder="请选择" v-model="form.orient">
									<el-option v-for="item in orientData" :key="item.id" :label="item.title" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="18">
							<el-form-item label="楼层">
								<el-input class="width_140" v-model="form.floor" maxlength='5' placeholder="请输入栋数"></el-input>
								<span class="span_left4">栋</span><span class="span_left10">共</span>
								<el-input class="width_140" v-model="form.floor_total" maxlength='5' @change="floor_totalChange" placeholder="请输入楼层"></el-input>
								<span class="span_left4">层 </span>
								<span class="span_left10"> 租 </span>
								<el-input class="width_140" v-model="form.floor_rent" maxlength='5' @change="floor_rentChange" placeholder="请输入楼层"></el-input>
								<span class="span_left4">层 </span>
							</el-form-item>
						</el-col>

					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="6">
							<el-form-item label="房型">
								<el-select placeholder="请选择" v-model="form.house_type" multiple :multiple-limit="limit">
									<el-option v-for="item in houseData" :key="item.id" :label="item.title" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="18">
							<el-form-item label="租金" prop='rent' :rules="[{required: true, message: '租金不能为空~', trigger: 'blur'}]">
								<el-input class="width_140" v-model="form.rent" maxlength='7' @change="rentChange"></el-input> <span class="span_left4">￥</span>
								<!-- <el-input class="width_140 span_left10" v-model="form.rent_date" @change="rent_dateChange" maxlength='3' ></el-input> -->
								<el-select placeholder="请选择" class="span_left4 width_140" v-model="form.rent_unit">
									<el-option v-for="item in rent_unitData" :key="item.id" :label="item.title" :value="item.id">
									</el-option>
								</el-select>
								<span class="span_left10"> 工位 </span>
								<el-input class="width_140" v-model="form.workstation" @change="workstationChange" maxlength='10'></el-input>
								<span class="span_left4">个</span>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="6">
							<el-form-item label="常用手机" prop='mobile' :rules="[{required: true, message: '手机号不能为空', trigger: 'blur'},{min: 11, max:11,message: '长度不能大于11个字符', trigger: 'change'}]">
								<el-input class="width_140" v-model="form.mobile" maxlength='11' @change="phoneChange"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="电话" prop='tel' :rules="[{min: 11, max:11,message: '长度不能大于或少于11个字符', trigger: 'change'}]">
								<el-input class="width_140" v-model="form.tel" maxlength='11' @change="phoneChange1"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="4">
							<el-form-item label="电费">
								<el-input class="width_140" v-model="form.elect_rent" maxlength='11' @change="elect_rentChange"></el-input>
								<span class="span_left4">/度</span>
							</el-form-item>
						</el-col>
						<el-col :span='4'>
							<el-form-item label="水费">
								<el-input class="width_140" v-model="form.water_rent" maxlength='11' @change="water_rentChange"></el-input>
								<span class="span_left4">/吨</span>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="物业费">
								<el-input class="width_140" v-model="form.management_rent" maxlength='11' @change="management_rentChange"></el-input>
								<span class="span_left4">/月</span>
							</el-form-item>
						</el-col>
						<el-col :span='4'>
							<el-form-item label="网络费">
								<el-input class="width_140" v-model="form.network_rent" maxlength='11' @change="network_rentChange"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="6">
							<el-form-item label="状态">
								<el-select placeholder="请选择" v-model="form.rent_state">
									<el-option v-for="item in rent_stateData" :key="item.id" :label="item.title" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="18">
							<el-form-item label="付费方式" prop="pay_type">
								<el-input class="width_140" v-model="form.pay_type" type='text' maxlenth='10' placeholder="请输入付费方式"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="12">
							<el-form-item label="基础设备">
								<el-checkbox-group v-model="form.device">
									<el-checkbox v-for="item in deviceData" :label="item.id" :key="item.id">{{item.title}}</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :span="24" :gutter="32">
						<el-col :span="12">
							<el-form-item label="简介">
								<el-input type="textarea" :rows="2" maxlength="1600" show-word-limit placeholder="请输入简介内容" v-model="form.description">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</el-main>
		<el-footer>
			<el-button type="primary" @click="saveAll('form')">发布</el-button>
		</el-footer>
	</el-container>
</template>
<script>
	import CardUpload from '@/components/Upload/CardUpload.vue';
	import {
		success,
		error,
		warning
	} from "@/utils/notification";
	import * as validata from '@/utils/validata';
	export default {
		data() {
			return {
				imgList: [], // 组件渲染页面图片数据
				images: [], // 调用接口传参 图片数据
				spaceId: 0, // 空间id
				uploadLogoAddress: this.URL.upLoad,
				form: {
					device: [],
					orient: [],
					house_type: [],
					park_type: [],
					province_id: '',
					city_id: '',
					district_id: '',
					description: '',
					address: '',
					pay_type: '',
					rent: '',
					rent_state: [],
					rent_unit: 3,
					images: [],
					network_rent: '',
					management_rent: '',
					elect_rent: '',
					water_rent: '',
					measure: '',
					workstation: '',
					floor_rent: '',
					floor_total: '',
					floor: '',
					mobile: '',
					tel: '',
					name: '',
					level_id:'',//空间级别
				},
				provinceData: [],
				cityData: [],
				districtData: [],
				deviceData: [],
				limit: 5,
				organizationId: 0, //机构id
				parkData: [], // 类型
				orientData: [], // 朝向
				houseData: [], // 房型
				tips: '',
				rent_unitData: [{
						title: '小时',
						id: 1
					},
					{
						title: '日',
						id: 2
					},
					{
						title: '月',
						id: 3
					},
					{
						title: '年',
						id: 4
					},
					{
						title: '平米/月',
						id: 6
					}					
				],
				rent_stateData: [{
						title: '待招租',
						id: 0
					},
					{
						title: '已租',
						id: 1
					},
				],
				level_ids:[],//空间级别列表

			}
		},
		components: {
			CardUpload
		},
		created() {
			this.getProvinces() // 获取省份
			this.getSpaceTypes()
			this.getHouseTypes()
			this.getOrientData()
			this.getInfrastructures()
			this.getLevels()//获取空间级别
			this.organizationId = sessionStorage.organizationId
			if (this.$route.query != undefined && this.$route.query.id) {
				this.spaceId = this.$route.query.id
				this.getSpaceDetails(this.spaceId)
			}
		},
		methods: {
			saveAll(form) { // 发布按钮事件
				let imgList = this.imgList
				let images = []
				if (imgList.length === 0) {
					error('至少上传一张图片')
					return false
				}
				imgList.map((v) => {
					images.push(v.path)
				})
				this.form.images = images
				this.form.organ_id = this.organizationId * 1
				this.form.id = this.spaceId * 1
				this.form.floor = this.form.floor
				this.form.floor_total = this.form.floor_total ? this.form.floor_total * 1 : 0
				this.form.floor_rent = this.form.floor_rent ? this.form.floor_rent * 1 : 0
				this.form.workstation = this.form.workstation ? this.form.workstation * 1 : 0
				this.form.water_rent = this.form.water_rent ? this.form.water_rent * 1 : 0
				this.form.elect_rent = this.form.elect_rent ? this.form.elect_rent * 1 : 0
				this.form.management_rent = this.form.management_rent ? this.form.management_rent * 1 : 0
				this.form.network_rent = this.form.network_rent ? this.form.network_rent * 1 : 0
				let that = this
				this.$refs[form].validate((valid) => {
					if (valid) {
						that.setSpace(that.form)
					} else {
						error('请检查必填项是否填写完整')
						return false;
					}
				});
			},
			setSpace(data) {
				this.$http
					.post(this.URL.adminUrl.setSpace, data)
					.then(res => {
						console.log(res, 'set')
						this.$router.go(-1)
					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			getSpaceDetails(spaceId) {
				this.$http
					.get(this.URL.adminUrl.setSpace + '/' + spaceId + '/edit')
					.then(res => {
						// console.log(res,'detail')
						let data = res.data.data
						let images = data.images
						images.map((v) => {
							let obj = {}
							obj.uid = ''
							obj.url = v
							obj.path = v
							this.imgList.push(obj)
						})
						this.form = data
						if (data.province_id) {
							this.editAreaData('cityData', data.province_id)
						}
						if (data.city_id) {
							this.editAreaData('districtData', data.city_id)
						}

					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			phoneChange(val) { // 手机号码
				let show = validata.checkPhoneNumber(val)
				// console.log(show,'ppp')
				if (!show) {
					this.form.mobile = ''
				}
			},
			phoneChange1(val) { // 电话
				let show = validata.checkPhoneNumber(val)
				if (!show) {
					this.form.tel = ''
				}
			},
			isNumber(val, data, text) { // 浮点型 统一处理方法
				let show = validata.checkNumber(val)
				if (!show) {
					warning('请输入正确的' + text)
					this.form[data] = ''
				}
			},
			intNumber(val) { // 大于0 的整数 统一处理方法
				let show = validata.onlyNumber(val)
				if (!show) {
					warning('请输入大于0的整数')
				}
			},
			toDataString(val) { // 表单中输入框返回0 ，统一转成空字符串
				if (val && val === 0) {
					return val = ''
				}
			},
			network_rentChange(val) { // 网费
				this.isNumber(val, 'management_rent', '网络费')
			},
			management_rentChange(val) { // 物业费
				this.isNumber(val, 'management_rent', '物业费')
			},
			water_rentChange(val) { // 水费
				this.isNumber(val, 'water_rent', '水费')
			},
			elect_rentChange(val) { // 电费
				this.isNumber(val, 'elect_rent', '电费')
			},
			workstationChange(val) { // 工位
				this.intNumber(val)
				this.form.workstation = validata.onlyNumber(val)
			},
			rentChange(val) { // 租金
				this.isNumber(val, 'rent', '租金')
			},
			measureChange(val) { // 面积发生改变的时候
				this.isNumber(val, 'measure', '单位面积')
			},
			floorChange(val) { // 楼层
				this.intNumber(val)
				this.form.floor = validata.onlyNumber(val)
			},
			floor_totalChange(val) { // 总楼层
				this.intNumber(val)
				this.form.floor_total = validata.onlyNumber(val)
				if (this.form.floor_rent) {
					if (this.form.floor_rent * 1 > this.form.floor_total * 1) {
						this.form.floor_rent = ''
						error('租出楼层数不能大于总楼层数')

					}
				}
			},
			floor_rentChange(val) { // 可出租楼层
				this.intNumber(val)
				this.form.floor_rent = validata.onlyNumber(val)
				if (!this.form.floor_total) {
					this.form.floor_total = this.form.floor_rent
				} else {
					if (this.form.floor_rent * 1 > this.form.floor_total * 1) {
						this.form.floor_rent = ''
						error('租出楼层数不能大于总楼层数')
					}

				}
			},
			getInfrastructures() { // 获取基础设置
				this.$http
					.get(this.URL.getInfrastructures)
					.then(res => {
						this.deviceData = res.data.data
					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			getOrientData() { // 获取朝向
				this.$http
					.get(this.URL.getDirections)
					.then(res => {
						this.orientData = res.data.data
					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			getSpaceTypes() { // 获取空间类型
				this.$http
					.get(this.URL.spaceTypes)
					.then(res => {
						this.parkData = res.data.data
					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			getHouseTypes() { // 获取房源类型
				this.$http
					.get(this.URL.houseTypes)
					.then(res => {
						this.houseData = res.data.data
					})
					.catch(err => {
						error(err.response.data.message);
					})
			},
			goBack() { // 返回上一级
				this.$router.go(-1)
			},
			imgUploadSuccess(response, filelist, file) { // 图片上传成功
				success('图片上传成功')
				if (filelist.length > 0) {
					filelist.map((v) => {
						if (v.uid === file.uid) {
							v.path = response.data.path
						}
					})
				}
				this.imgList = filelist
			},
			handleExceed() {
				error('上传图片不能超过20张')
			},
			imgRemove(filelist) { // 图片移除
				this.imgList = filelist
			},
			areaChange1() { // 当省市发生改变时调用
				let that = this;
				that.getArea(this.form.province_id).then(res => {
					that.cityData = res.data.data
				})
				if (this.form.city_id) {
					this.form.city_id = ''
				}
				if (this.form.district_id) {
					this.form.district_id = ''
					this.districtData = []
				}
			},
			areaChange2() { // 当市级发生改变时调用
				let that = this;
				that.getArea(this.form.city_id).then(res => {
					that.districtData = res.data.data
				})
				if (this.form.district_id) {
					this.form.district_id = ''
					this.districtData = []
				}
			},
			getProvinces() { // 获取省的接口数据
				this.getArea(0).then(res => {
					this.provinceData = res.data.data
				})
			},
			getArea(pid) { // 请求地区信息的接口
				return this.$http
					.get(this.URL.getArea, {
						params: {
							city_id: pid
						}
					})
					.then(res => {
						return res
					})
					.catch(err => {
						console.log(err)
					})
			},
			getEditData(pid, data) { // 编辑时获取数据
				this.getArea(pid).then(res => {
					this[data] = res.data.data
				})
			},
			editAreaData(data, val) { //编辑时获取地区数据
				this.getArea(val).then(res => {
					this[data] = res.data.data
				})
			},
			//获取空间级别
			getLevels(){
				this.$http
					.get(this.URL.adminUrl.space_level)
					.then(res=>{
						this.level_ids=res.data.data
					})
			}

		}
	}
</script>
<style lang="less" scoped>
	.add_space {
		background: #fff;

		.width_360 {
			width: 360px;
		}

		.width_140 {
			width: 140px;
		}

		.span_left4 {
			margin-left: 4px;
		}

		.span_left10 {
			margin-left: 10px;
			margin-right: 4px;
		}

		.must_red::before {
			content: '*';
			color: #F56C6C;
			margin-right: 4px;
		}

		.go_back {
			height: 40px !important;
		}

		.tips {
			font-size: 14px;
			margin-top: 8px;
		}

		.lh30 {
			line-height: 30px;
		}

		.title {
			color: #409EFF;
		}
	}
</style>
